'use strict';

Gri.module({
  name: 'page-arge',
  ieVersion: null,
  $el: $('.page-arge'),
  container: '.page-arge',
  fn: function () {
  }
});
